<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <div class="spinner" v-if="isLoading"><div><div></div></div></div>
        <!-- Header -->
        <section class="header index">
            <a class="left" @click.prevent="$router.push('/record')">
                <img class="logo" src="@/assets/img/favicon.svg">
                <span class="primary">{{ displayUserPointBalance }}</span>
            </a>
            <div class="uide-page-name">優惠地圖</div>
            <div class="right">
                <!-- <a class="icon iconButton" @click.prevent="barcodePopupOpen = true"> -->
                <a class="icon iconButton" @click.prevent="$router.push('/barcode')">                                        
                    <img  src="@/assets/img/barcode.svg">
                </a>
                <NotificationBell
                v-if="triggerCountUnread"
                @count-unread="countUnread = $event"
            />
            </div>
        </section>
        <!-- Tabs -->
        <div class="uide-tabs main-top">     
            <a class="uide-tab active" @click.prevent="$router.push('/stores')">
                    <span class="fM">特店列表</span>
            </a>
                
            <a class="uide-tab " @click.prevent="$router.push('/map')">
                    <span class="fM">特店地圖</span>
            </a>
                
        </div>
         <!-- 搜尋 -->
        <div class="uide-header-bottom-wrap">
            <div class="input rounded uide-store-search">
                <a class="icon iconButton"><font-awesome-icon icon="fas fa-search" size="sm"></font-awesome-icon></a>
                <input type="text" class="fM" placeholder="搜尋關鍵字" v-model="filterName" >                
            </div>
            <div class="uide-filter-wrap">
                    <a class="uide-filter" @click.prevent="categoryFilterPopOpen = true">
                        <span class="">{{ filterCategory.name }}</span>
                        <div class="icon ">
                            <font-awesome-icon
                                icon="fa-solid fa-caret-down"
                                size="xs"
                            />
                        </div>
                    </a>                    
                    <a class="uide-filter"  @click.prevent="regionFilterPopOpen = true">
                        <span class="">{{ filterRegion.name }}</span>
                        <div class="icon ">
                            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
                        </div>
                    </a>
            </div>
        </div>
        
        <!-- Main -->
        <section class="main stores no-top mt-2">
          
            <!-- Filter -->
            
            <!-- List -->
            <div class="list flexV width">
                
                <!-- 特店資訊 -->
                <a class="uide-store-card" @click.prevent="$router.push(`/stores/${store.id}/info`)" v-for="(store, index) in matchingStores" :key="'store'+index">
                    <!-- class: hide -->
                    <!-- <div class="flex"> -->
                        <span class="uide-store-card-name">{{store.name}}</span>
                        <span class="uide-store-card-address">{{store.address}}</span>
                        <span class="uide-store-card-category">{{store.categoryName}}</span>                        
                        <!-- <div class="detail flexH">
                            <div>
                                <img src="@/assets/icon/point.svg">
                                <span class="fS gray">雲林幣</span>
                            </div>
                            <div v-if="store.isDdpayEnabled">
                                <font-awesome-icon icon="fa-solid fa-wallet"></font-awesome-icon>
                                <span class="fS gray">行動支付</span>
                            </div>
                            <div v-if="store.isQcEnabled">
                                <font-awesome-icon icon="fa-solid fa-utensils"></font-awesome-icon>
                                <span class="fS gray">線上點餐</span>
                            </div>
                            
                        </div> -->
                    <!-- </div> -->
                    <!-- <img class="store" src="@/assets/img/store.png"> -->
                </a>
            </div>
        </section>
        <!-- Popup (下拉選單) -->
        <FilterPopup
            v-if="categoryFilterPopOpen"
            @closePopup="categoryFilterPopOpen = false"
            v-slot="pop"
        >
            <section class="popHeader">
                <span class="fL">店家分類</span>
            </section>
            <section class="popMain" @click.prevent="pop.close">
                <a
                    @click.prevent="changeCategoryFilter({id: 0, name: '全部分類'})"
                    :class="{ active: filterCategory.id == 0 }"
                ><span>全部分類</span></a
                >
                <a
                    v-for="(category, index) in categories"
                    :key="'category_' + index"
                    :class="{ active: filterCategory.id == category.id }"
                    @click.prevent="changeCategoryFilter(category)"
                    ><span>{{ category.name }}</span></a
                >
            </section>
        </FilterPopup>
        <FilterPopup
            v-if="regionFilterPopOpen"
            @closePopup="regionFilterPopOpen = false"
            v-slot="pop"
        >
            <section class="popHeader">
                <span class="fL">地區</span>
            </section>
            <section class="popMain" @click.prevent="pop.close">
                <a
                    @click.prevent="changeRegionFilter({id: -1, name:'全部地區'})"
                    :class="{ active: filterRegion.id == -1 }"
                ><span>全部地區</span></a
                >
                <a
                    v-for="(region, index) in regions"
                    :key="'region_' + index"
                    :class="{ active: filterRegion.id == region.id }"
                    @click.prevent="changeRegionFilter(region)"
                    ><span>{{ region.name }}</span></a
                >
            </section>
        </FilterPopup>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import NotificationBell from "@/components/NotificationBell.vue";
import FilterPopup from '@/components/FilterPopup.vue';

export default {
    name: "Stores",
    components: {
        NotificationBell,
        FilterPopup,
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            ecUrl: null,
            stores: [],
            displayStores: [],
            countUnread: 0,
            categories: [],
            filterCategory: {id: 0, name:'全部分類'},
            filterRegion: {id: -1, name: '全部地區'},
            regions: [],
            filterName: "",
            isLoading: false,
            triggerCountUnread: false,
            categoryFilterPopOpen: false,
            regionFilterPopOpen: false,
        };
    },
    async mounted() {
        this.$store.dispatch('getUserPoints');
        this.getCategories()
        this.getZones()
        await this.getStores()
        this.getEcUrl()
    },
    activated() {
        this.triggerCountUnread = true;
    },
    deactivated() {
        this.triggerCountUnread = false;
        this.isLoading = false;
        this.closeFilterPop();
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters({
            userPoints: "getTyUserPoints",
        }),
        matchingStores() {
            return this.displayStores.filter(
                store =>
                store.name.toLowerCase().includes(this.filterName.toLowerCase())
            );
        },
        displayUserPointBalance() {
            return this.userPoints && this.userPoints.pointBalance
                ? this.userPoints.pointBalance
                : 0;
        },
    },
    watch: {
      filterRegion(val) {
        this.getStores(val.id, this.filterCategory.id);
      },
      filterCategory(val) {
        this.getStores(this.filterRegion.id, val.id);
      }
    },
    methods: {
        dummyStores() {
            let response = [
                {
                    "name": "測試商店",
                    "address": "測試地址",
                    "phone": "0987654321",
                    "banId": "85111267",
                    "storePlaceId": "ChIJx3Qv-KchaDQRA1lVKGSYsVQ",
                    "geometry": JSON.stringify({
                        "location": {
                            "lat": 25.0109536,
                            "lng": 121.2173832
                        },
                        "viewport": {
                            "south": 25.0100204697085,
                            "west": 121.2164427697085,
                            "north": 25.0127184302915,
                            "east": 121.2191407302915
                        }
                    }),
                    "categoryName": "餐飲",
                    "categoryId": 1,
                    "addressComponents": JSON.stringify([
                        {
                            "long_name": "9",
                            "short_name": "9",
                            "types": [
                                "street_number"
                            ]
                        },
                        {
                            "long_name": "航站南路",
                            "short_name": "航站南路",
                            "types": [
                                "route"
                            ]
                        },
                        {
                            "long_name": "大園區",
                            "short_name": "大園區",
                            "types": [
                                "administrative_area_level_3",
                                "political"
                            ]
                        },
                        {
                            "long_name": "桃園市",
                            "short_name": "桃園市",
                            "types": [
                                "administrative_area_level_1",
                                "political"
                            ]
                        },
                        {
                            "long_name": "台灣",
                            "short_name": "TW",
                            "types": [
                                "country",
                                "political"
                            ]
                        },
                        {
                            "long_name": "33758",
                            "short_name": "33758",
                            "types": [
                                "postal_code"
                            ]
                        }
                    ])
                },
                {
                    "name": "qwe",
                    "address": "asdf",
                    "phone": "321564897",
                    "banId": "85111246",
                    "storePlaceId": "ChIJ1RXSYsCfQjQRCbG1qZC2o3A",
                    "geometry": JSON.stringify({
                        "location": {
                            "lat": 25.0796514,
                            "lng": 121.234217
                        },
                        "viewport": {
                            "south": 25.05372919999999,
                            "west": 121.2032256,
                            "north": 25.10275000000001,
                            "east": 121.2624304
                        }
                    }),
                    "categoryName": "餐飲",
                    "categoryId": 1,
                    "addressComponents": JSON.stringify([
                        {
                            "long_name": "9",
                            "short_name": "9",
                            "types": [
                                "street_number"
                            ]
                        },
                        {
                            "long_name": "航站南路",
                            "short_name": "航站南路",
                            "types": [
                                "route"
                            ]
                        },
                        {
                            "long_name": "大大區",
                            "short_name": "大大區",
                            "types": [
                                "administrative_area_level_3",
                                "political"
                            ]
                        },
                        {
                            "long_name": "桃園市",
                            "short_name": "桃園市",
                            "types": [
                                "administrative_area_level_1",
                                "political"
                            ]
                        },
                        {
                            "long_name": "台灣",
                            "short_name": "TW",
                            "types": [
                                "country",
                                "political"
                            ]
                        },
                        {
                            "long_name": "33758",
                            "short_name": "33758",
                            "types": [
                                "postal_code"
                            ]
                        }
                    ])
                }
            ];
            let promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({data: response});
                }, 100);
            });
            return promise;
        },
        getStores(zoneId = -1, categoryId = 0) {
            let config = {
                url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/portals/yunlin/stores`,
                method: "get"
            };
            
            let params = {};
            if (zoneId != '-1') params['zone_id'] = zoneId;
            
            if (categoryId != '0') params['category_id'] = categoryId;
            
            if (Object.keys(params).length) config.params = params;

            return this.$http(config)
            .then((response) => {
                console.log("response: ", response);
                this.stores = JSON.parse(JSON.stringify(response.data))
                this.stores.forEach(store => {
                    if (store.geometry) {
                        store.geometry = JSON.parse(store.geometry);
                    }
                    if (store.addressComponents) {
                        store.addressComponents = JSON.parse(store.addressComponents);
                    }
                })
                
                console.log("this.stores: ", this.stores)
                this.displayStores = JSON.parse(JSON.stringify(this.stores))
                return;
            });
        },
        callCategoriesAPI() {
            let config = {
                url: `${this.apiHost}/stores/v1/store-categories`,
                method: "get"
            };

            return this.$http(config)
        },
        getCategories() {
            this.callCategoriesAPI()
            .then((response) => {
                console.log("response: ", response);
                this.categories = response.data;
            });
        },
        getZones() {
            let config = {
                url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/portals/yunlin/zones`,
                method: "get"
            };

            return this.$http(config)
            .then((response) => {
                console.log("zone response: ", response);
                this.regions = response.data;
            });
            
        },
        callGetEcUrlAPI() {
            let config = {
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/app-auth/ec`,
                method: 'get',
            };
            return this.$http(config);
        },
        getEcUrl() {
            this.callGetEcUrlAPI().then((res) => {
                this.ecUrl = res.data.authUrl;
            });
        },
        goToEc() {
            this.isLoading = true;
            setTimeout(()=> {
                this.isLoading = false;
            }, 1000);
            window.location.href = this.ecUrl;
        },
        closeFilterPop() {
            this.categoryFilterPopOpen = false;
            this.regionFilterPopOpen = false;
        },
        changeCategoryFilter(category) {
            this.filterCategory = category;
        },
        changeRegionFilter(region) {
            this.filterRegion = region;
        },
    }
};
</script>
